import { loadPrimer } from './load';
import { fetchClientConfiguration } from '../core/fetchClientConfiguration';
import { uuid } from '../utils/uuid';
import { getAnalytics } from '@primer-sdk-web/analytics/providers/httpAnalyticsProvider';
import { timerIdCheckoutFlowMap } from '@primer-sdk-web/analytics';

type PrimerClient = typeof import('../Primer');
type Tail<T extends unknown[]> = T extends [any, any, ...infer P] ? P : never;
type PrimerFunctions = Exclude<keyof PrimerClient, 'SDK_VERSION'>;
type ArgsOf<T extends PrimerFunctions> = Tail<Parameters<PrimerClient[T]>>;
type ReturnTypeOf<T extends PrimerFunctions> = Awaited<
  ReturnType<PrimerClient[T]>
>;

export const SDK_VERSION = process.env.PRIMER_SDK_VERSION as string;

export const createHeadless = lazyLoad('createHeadless');
export const showExpressCheckout = lazyLoad('showExpressCheckout');
export const showUniversalCheckout = lazyLoad('showUniversalCheckout');
export const showVaultManager = lazyLoad('showVaultManager');

function lazyLoad<FnName extends PrimerFunctions>(name: FnName) {
  return async (
    ...[clientToken, options, ...rest]: ArgsOf<FnName>
  ): Promise<ReturnTypeOf<FnName>> => {
    const checkoutSessionId = uuid();
    const analytics = getAnalytics(checkoutSessionId);

    analytics.sdkFunctionEvent({
      name,
      params: [clientToken, options, ...rest],
    });

    analytics.timerStart({ id: timerIdCheckoutFlowMap[name] });

    const [config, client] = await Promise.all([
      fetchClientConfiguration(
        clientToken,
        checkoutSessionId,
        options?.clientSessionCachingEnabled
          ? {
              key: clientToken,
              usePrimerSessionCacheTtlHeader: true,
            }
          : undefined,
      ),
      loadPrimer(analytics),
      // startMockServiceWorker(),
    ]);

    return client[name](
      config,
      checkoutSessionId,
      clientToken,
      options,
    ) as ReturnTypeOf<FnName>;
  };
}

// IMPORTANT:
//   `msw` can be used to mock calls locally when in development,
//   but due to a webpack issue (essentially poor support for tree-shaking),
//   having this code at all, regardless of a "NODE_ENV" guard clause,
//   will cause the build to fail silently, meaning it builds,
//   but it does not run afterwards.
//
//   Feel free to uncomment the function and its call above inside `lazyLoad`,
//   but be mindful to comment it again when done testing/mocking,
//   so as not to break the build.
//
//
// async function startMockServiceWorker() {
//   const { worker } = await import('../_msw/browser');
//   await worker.start({ onUnhandledRequest: 'bypass' });
// }
