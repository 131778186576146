import { PaymentMethodConfig } from '@primer-sdk-web/models/ClientConfiguration';
import { BasePaymentMethod } from '@primer-sdk-web/payment-methods/BasePaymentMethod';

export type AsyncPaymentMethodTokenizeOptions = any;

export interface AsyncPaymentMethodRemoteOptions extends PaymentMethodConfig {
  id: string;
}

export default class BaseAsyncPaymentMethod extends BasePaymentMethod {
  public remoteConfig: AsyncPaymentMethodRemoteOptions;

  public async tokenize(options?: AsyncPaymentMethodTokenizeOptions) {}
}
