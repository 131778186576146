/**
 * Waits for a specified number of milliseconds
 * @param duration - Number of milliseconds to wait
 * @returns A promise that resolves after the specified time
 */
export function delay(duration: number): Promise<void> {
  if (!duration) return Promise.resolve();

  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}
