import { delay } from './delay';

/** Maximum number of retry attempts */
export const MAX_RETRIES = 8; // 8 retries would be in range of 13s to 26s excluding network latency/timeouts

/** Initial backoff time in milliseconds */
export const INITIAL_BACKOFF = 100; // 0.1 seconds

/** Maximum jitter in milliseconds */
export const MAX_JITTER = 100;

/**
 * Configuration options for the retry mechanism
 */
export interface RetryConfig {
  /** Whether retry is enabled */
  enabled: boolean;
}

/**
 * Logger function triggered after each attempt and at the end when failed or succeeded
 */
export type RetryLogFunction = (message: {
  messageType: 'RETRY' | 'RETRY_FAILED' | 'RETRY_SUCCESS';
  message: string;
  severity: 'INFO' | 'WARN' | 'ERROR';
  errorBody?: string;
}) => void;

/**
 * Extended Error interface for retryable errors
 */
export interface RetryableError extends Error {
  /** Indicates if the error is a network error */
  isNetworkError?: boolean;
  /** HTTP status code of the error */
  status?: number;
}

/**
 * Creates a complete RetryConfig object with default values for missing properties
 * @param config - Partial RetryConfig object
 * @returns A complete RetryConfig object with all properties set
 */
function createRetryConfig(config?: Partial<RetryConfig>) {
  return {
    enabled: config?.enabled ?? false,
    maxRetries: MAX_RETRIES,
    initialBackoff: INITIAL_BACKOFF,
    retryNetworkErrors: true,
    retry500Errors: false,
    maxJitter: MAX_JITTER,
  };
}

/**
 * Calculates the next backoff time using exponential backoff with jitter
 * @param baseDelay - The base delay for exponential backoff
 * @param retryCount - The current retry attempt number
 * @param maxJitter - The maximum jitter to add (in milliseconds)
 * @returns The calculated backoff time in milliseconds
 */
function calculateBackoffWithJitter(
  baseDelay: number,
  retryCount: number,
  maxJitter: number,
): number {
  const exponentialPart = baseDelay * Math.pow(2, retryCount - 1);
  const jitterPart = Math.random() * maxJitter;
  return Math.min(exponentialPart + jitterPart, Number.MAX_SAFE_INTEGER);
}

/**
 * Retries a function with exponential backoff and jitter
 * @param retryFunction - The async function to retry
 * @param retryConfig - Configuration for the retry mechanism
 * @param logError - Optional function to log errors
 * @param logSuccess - Optional function to log successful call
 * @param waitFunction - Optional function to wait for a specified time
 * @returns A promise that resolves with the result of the function or rejects if all retries fail
 */
export async function retryWithExponentialBackoff<T>(
  retryFunction: () => Promise<T>,
  retryConfig?: RetryConfig,
  log?: RetryLogFunction,
  waitFunction: (ms: number) => Promise<void> = delay,
): Promise<T> {
  let retries = 0;
  const config = createRetryConfig(retryConfig);

  if (!config.enabled) {
    return retryFunction();
  }

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const result = await retryFunction();

      if (retries) {
        let message = `Request succeeded after ${retries} retries.`;

        // Check if status property exists.
        // Simplest way without too many castings where TS don't complain
        if (typeof result === 'object' && result && 'status' in result) {
          message += ` Status code: ${result.status}`;
        }

        log?.({
          messageType: 'RETRY_SUCCESS',
          message,
          severity: 'INFO',
        });
      }

      return result;
    } catch (error) {
      const retryableError = error as RetryableError;
      const isLastAttempt = retries >= config.maxRetries;
      const isNetworkError =
        retryableError.isNetworkError && !config.retryNetworkErrors;
      const is500Error =
        retryableError.status &&
        retryableError.status >= 500 &&
        !config.retry500Errors;

      if (isLastAttempt || isNetworkError || is500Error) {
        let finalErrorMessage = `Failed after ${retries} retries. `;
        if (isLastAttempt) {
          finalErrorMessage += `Reached maximum retries (${config.maxRetries}).`;
        } else if (isNetworkError) {
          finalErrorMessage +=
            'Network error encountered and retryNetworkErrors is disabled.';
        } else if (is500Error) {
          finalErrorMessage +=
            'HTTP 500 error encountered and retry500Errors is disabled.';
        }
        finalErrorMessage += ` Last error: ${retryableError.message}`;

        log?.({
          messageType: 'RETRY_FAILED',
          message: finalErrorMessage,
          severity: 'ERROR',
          errorBody: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        });

        throw new Error(finalErrorMessage, { cause: error });
      }

      retries++;
      const backoffTime = calculateBackoffWithJitter(
        config.initialBackoff,
        retries,
        config.maxJitter,
      );

      let retryReason = 'Unknown error';
      if (retryableError.isNetworkError) {
        retryReason = 'Network error';
      } else if (retryableError.status) {
        retryReason = `HTTP ${retryableError.status} error`;
      }

      log?.({
        messageType: 'RETRY',
        message:
          `Retry attempt ${retries}/${config.maxRetries} due to: ${retryReason}. ` +
          `Error details: ${retryableError.message}. ` +
          `Waiting for ${backoffTime.toFixed(2)}ms before next attempt.`,
        severity: 'WARN',
        errorBody: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });

      await waitFunction(backoffTime);
    }
  }
}
