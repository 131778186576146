import { APIRequestOptions, APIResponse } from '../../core/Api';
import { NetworkCallType, NetworkEventProperties } from '../types';

export const prepareNetworkEventProps = (
  id: string,
  payload: APIRequestOptions,
  response?: PartialNonNullable<APIResponse<any>>,
  duration?: number,
): NetworkEventProperties => ({
  callType: response
    ? NetworkCallType.REQUEST_END
    : NetworkCallType.REQUEST_START,
  id,
  errorBody:
    response?.error &&
    JSON.stringify(response.error, Object.getOwnPropertyNames(response.error)),
  method: payload.method,
  responseCode: response?.status,
  url: payload.url,
  duration,
});

type PartialNonNullable<T> = {
  [P in keyof T]?: Exclude<T[P], null>;
};
