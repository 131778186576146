import { Api, APIRequestOptions, HttpMethod } from '../../core/Api';
import { uuid } from '../../utils/uuid';
import { Analytics } from '../index';
import { prepareNetworkEventProps } from './index';

const functionsForProxy = new Set(['post', 'get', 'delete']);
const methodsWithBody = new Set(['post']);

function createNetworkPayload(
  method: HttpMethod,
  ...args: unknown[]
): APIRequestOptions;
function createNetworkPayload(
  method: HttpMethod,
  url: string,
  bodyOrOptions: unknown,
  maybeOptions?: APIRequestOptions['options'],
): APIRequestOptions {
  const hasBody = methodsWithBody.has(method);
  const body = hasBody ? bodyOrOptions : undefined;
  const options = (
    hasBody ? maybeOptions : bodyOrOptions
  ) as APIRequestOptions['options'];

  return { method, options, body, url };
}

export function createNetworkAnalyticsProxy(
  api: Api,
  analytics: Analytics,
): Api {
  const createFunctionProxyHandler = ({ prop }: { prop: PropertyKey }) => ({
    apply: async (
      target: () => void,
      thisArg: unknown,
      args: unknown[] = [],
    ) => {
      const payload = createNetworkPayload(
        prop.toString() as HttpMethod,
        ...args,
      );
      if (payload.options?.isAnalytics) {
        return target.apply(thisArg, args);
      }

      const requestId = uuid();

      analytics?.networkCallEvent(prepareNetworkEventProps(requestId, payload));

      const start = performance?.now();

      const response = await target.apply(thisArg, args);

      const end = performance?.now();
      const duration = end - start;

      analytics?.networkCallEvent(
        prepareNetworkEventProps(requestId, payload, response, duration),
      );

      return response;
    },
  });

  return new Proxy(api, {
    get(target: Api, prop: PropertyKey) {
      const targetProp = target[prop];

      if (
        typeof targetProp === 'function' &&
        functionsForProxy.has(prop as string)
      ) {
        return new Proxy(targetProp, createFunctionProxyHandler({ prop }));
      }

      return targetProp;
    },
  });
}
