import { Analytics } from '@primer-sdk-web/analytics';
import {
  RetryConfig,
  retryWithExponentialBackoff,
} from '@primer-sdk-web/utils/retryWithExponentialBackoff';

interface LoadOptions {
  name?: string;
  attributes?: Record<string, string>;
  analytics?: Analytics;
  retryConfig?: RetryConfig;
}

function createElement(
  type: 'link' | 'script',
  attributes: Record<string, string>,
): HTMLElement {
  const element = document.createElement(type);
  Object.entries(attributes).forEach(([name, value]) =>
    element.setAttribute(name, value),
  );
  return element;
}

function getExistingElement(
  type: 'link' | 'script',
  srcAttr: 'href' | 'src',
  source: string,
): HTMLElement | null {
  return document.querySelector(`${type}[${srcAttr}^="${source}"]`);
}

async function loadResource(
  type: 'link' | 'script',
  source: string,
  srcAttr: 'href' | 'src',
  attributes: Record<string, string>,
  errorMessage: string,
  options: LoadOptions,
): Promise<void> {
  const { analytics, name = source, retryConfig } = options;

  const loadOperation = () =>
    new Promise<void>((resolve, reject) => {
      const element =
        getExistingElement(type, srcAttr, source) ||
        createElement(type, { ...attributes, [srcAttr]: source });

      element.onload = () => {
        analytics?.sdkFunctionEvent({
          name,
          params: [
            `${
              type.charAt(0).toUpperCase() + type.slice(1)
            } loaded successfully: ${source}`,
          ],
        });
        resolve();
      };
      element.onerror = () => {
        element.remove();
        reject(new Error(`${errorMessage}: ${source}`));
      };

      if (!element.parentNode) {
        document.head.appendChild(element);
      }
    });

  await retryWithExponentialBackoff(
    loadOperation,
    retryConfig,
    (message) => analytics?.messageEvent({ ...message, url: source }),
  );
}

export function loadScript(
  source: string,
  options: LoadOptions = {},
): Promise<void> {
  // eslint-disable-next-line no-underscore-dangle
  if (getExistingElement('script', 'src', source) && window.__Primer) {
    options.analytics?.sdkFunctionEvent({
      name: options.name ?? source,
      params: ['Script already loaded'],
    });
    return Promise.resolve();
  }

  return loadResource(
    'script',
    source,
    'src',
    {
      ...options.attributes,
      async: '',
      crossorigin: 'anonymous',
    },
    "Can't load Primer SDK",
    options,
  );
}

export function loadStylesheet(
  source: string,
  options: LoadOptions = {},
): Promise<void> {
  if (Array.from(document.styleSheets).some((s) => s.href === source)) {
    options.analytics?.sdkFunctionEvent({
      name: options.name ?? source,
      params: ['Stylesheet already loaded'],
    });
    return Promise.resolve();
  }

  return loadResource(
    'link',
    source,
    'href',
    {
      ...options.attributes,
      rel: 'stylesheet',
    },
    "Can't load Primer SDK's Stylesheet",
    options,
  );
}

type Primer = typeof import('../Primer');

let clientPromise: Promise<Primer> | undefined;

export async function loadPrimer(analytics?: Analytics): Promise<Primer> {
  if (clientPromise) return clientPromise;

  const primerCssUrl = process.env.PRIMER_CSS_URL as string;
  const primerSdkUrl = process.env.PRIMER_SDK_URL as string;

  clientPromise = Promise.all([
    loadScript(primerSdkUrl, {
      analytics,
      name: 'loadPrimerScript',
    }),
    loadStylesheet(primerCssUrl, { analytics, name: 'loadPrimerCss' }),
  ])
    .then(() => {
      const Primer = window.__Primer as Primer;
      delete window.__Primer;
      return Primer;
    })
    .catch((err) => {
      clientPromise = undefined;
      throw err;
    });

  return clientPromise;
}

declare global {
  interface Window {
    __Primer?: Primer;
  }
}
